



































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Firmware } from '@/models/firmware'
import { logAdd } from '@/models/log'

@Component
export default class FirmwareUpdateVersion extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Firmware>
  @Ref('form') form!: { validate(): boolean }

  menu = false
  version = this.item.version
  rules = [
    (v: string): boolean | string => !!v || '버전을 지정해야합니다',
    (v: string): boolean | string => (v.split('.').length === 4 && v.length > 6 && v.length < 9) || '유효하지 않은 버전입니다'
  ]

  get item (): Firmware {
    return this.doc.data()
  }

  save (): void {
    if (!this.form.validate()) return
    this.doc.ref.set({ version: this.version }, { merge: true })
    this.menu = false
    logAdd('update', 'firmware version', `${this.item.version} => ${this.version}`, 'admin')
    // this.$toast.success('good')
  }
}
