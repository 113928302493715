








import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from '@/plugins/moment'
@Component
export default class DisplayTime extends Vue {
  @Prop({ type: Date, required: true }) readonly time!: Date

  get displayTime (): string {
    if (!this.time) return '잘못된 시간'
    const bt = moment(this.time)
    const ct = moment()
    const diff = ct.diff(bt, 'days')
    if (!diff) return bt.fromNow()
    return bt.toDate().toLocaleDateString()
  }

  get localeString (): string {
    if (!this.time) return '잘못된 시간'
    return this.time.toLocaleString()
  }
}
