













































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Firmware } from '@/models/firmware'
import { logAdd } from '@/models/log'

@Component
export default class FirmwareUpdateDescription extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Firmware>
  @Ref('form') form!: { validate(): boolean }

  menu = false
  description = this.item.description

  get item (): Firmware {
    return this.doc.data()
  }

  save (): void {
    if (!this.form.validate()) return
    this.doc.ref.set({ description: this.description }, { merge: true })
    this.menu = false
    logAdd('update', '펌웨어 설명 변경', `${this.item.description} => ${this.description}`, 'admin')
  }
}
