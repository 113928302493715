

































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase, { storage } from '@/plugins/firebase'
import { Firmware } from '@/models/firmware'
import DisplayBytes from '@/components/common/DisplayBytes.vue'
import DisplayTime from '@/components/common/DisplayTime.vue'
import FirmwareUpdateVersion from '@/components/manage/FirmwareUpdateVersion.vue'
import FirmwareUpdateName from '@/components/manage/FirmwareUpdateName.vue'
import FirmwareUpdateBufferSize from '@/components/manage/FirmwareUpdateBufferSize.vue'
import FirmwareUpdateDescription from '@/components/manage/FirmwareUpdateDescription.vue'

@Component({
  components: {
    DisplayBytes,
    DisplayTime,
    FirmwareUpdateVersion,
    FirmwareUpdateName,
    FirmwareUpdateBufferSize,
    FirmwareUpdateDescription
  }
})
export default class FirmwareItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Firmware>

  version = this.item.version

  get item (): Firmware {
    return this.doc.data()
  }

  remove (): void {
    const r = confirm('정말 삭제하시겠습니까?')
    if (!r) return
    this.doc.ref.delete()
  }

  async download (): Promise<void> {
    const ref = storage.ref('firmwares').child(this.doc.id).child(this.item.fileName)
    const url = await ref.getDownloadURL()
    const a: HTMLAnchorElement = document.createElement('A') as HTMLAnchorElement
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
