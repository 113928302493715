

















import { Component, Vue } from 'vue-property-decorator'
import FirmwareList from '@/components/manage/FirmwareList.vue'
import FirmwareAdd from '@/components/manage/FirmwareAdd.vue'

@Component({
  components: { FirmwareList, FirmwareAdd }
})
export default class FirmwareIndex extends Vue {

}
