



















import { Component, Vue } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import firmwareCollection, { Firmware } from '@/models/firmware'
import FirmwareItem from '@/components/manage/FirmwareItem.vue'

@Component<FirmwareList>({
  components: {
    FirmwareItem
  },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class FirmwareList extends Vue {
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Firmware>[] = []

  // get items (): Firmware[] {
  //   return this.docs.map(doc => Object.assign(doc.data(), { id: doc.id }))
  // }

  subscribe (): void {
    this.unsubscribe = firmwareCollection.limit(100).onSnapshot(sn => {
      this.docs = sn.docs
    })
  }
}
