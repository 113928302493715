






























































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import firmwareCollection, { Firmware } from '@/models/firmware'
import { storage } from '@/plugins/firebase'
import { logAdd } from '@/models/log'

@Component<FirmwareAdd>({
  mounted () {
    // console.log('mount')
  }
})
export default class FirmwareAdd extends Vue {
  @Ref('form') form!: { validate(): boolean; reset(): void }
  dialog = false
  file: File | null = null
  loading = false
  modelNo = ''
  name = ''
  bufferSize = 4096
  remark = ''
  version = ''

  models = ['LDT-300BS']

  versionRules = [
    (v: string): boolean | string => !!v || '버전을 지정해야합니다',
    (v: string): boolean | string => (!!v && v.length > 6 && v.length < 9 && v.split('.').length === 4) || '유효하지 않은 버전입니다'
  ]

  @Watch('dialog')
  async onChangeDialog (opened: boolean): Promise<void> {
    if (!opened) return
    this.$nextTick(() => {
      this.form.reset()
    })
  }

  async submit (): Promise<void> {
    if (!this.form.validate()) return
    try {
      this.loading = true
      if (!this.file) throw Error('파일을 선택해야합니다')
      const firmware = new Firmware(
        this.file.name,
        this.file.size,
        this.modelNo,
        this.name,
        this.bufferSize,
        this.version,
        this.remark
      )
      const sn = await firmwareCollection.add(firmware)
      await storage.ref('firmwares').child(sn.id).child(this.file.name).put(this.file)
      this.dialog = false
      logAdd('create', 'firmware add', `${firmware.name} ${firmware.version}`, 'admin')
    } finally {
      this.loading = false
    }
  }

  change (file: File): void {
    this.file = file
  }
}
