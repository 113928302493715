



































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Firmware } from '@/models/firmware'
import { logAdd } from '@/models/log'

@Component
export default class FirmwareUpdateBufferSize extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Firmware>
  @Ref('form') form!: { validate(): boolean }

  menu = false
  bufferSize = this.item.bufferSize
  rules = [
    (v: number): boolean | string => (v > 0 && v < 10000) || '전송량이 범위를 초과합니다'
  ]

  get item (): Firmware {
    return this.doc.data()
  }

  save (): void {
    if (!this.form.validate()) return
    this.doc.ref.set({ bufferSize: this.bufferSize }, { merge: true })
    this.menu = false
    logAdd('update', 'firmware bufferSize', `${this.item.bufferSize} => ${this.bufferSize}`, 'admin')
    // this.$toast.success('good')
  }
}
