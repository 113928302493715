



































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Firmware } from '@/models/firmware'
import { logAdd } from '@/models/log'

@Component
export default class FirmwareUpdateName extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Firmware>
  @Ref('form') form!: { validate(): boolean }

  menu = false
  name = this.item.name
  rules = [
    (v: string): boolean | string => !!v || '이름을 입력해야합니다'
  ]

  get item (): Firmware {
    return this.doc.data()
  }

  save (): void {
    if (!this.form.validate()) return
    this.doc.ref.set({ name: this.name }, { merge: true })
    this.menu = false
    logAdd('update', 'firmware name', `${this.item.name} => ${this.name}`, 'admin')
    // this.$toast.success('good')
  }
}
